<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="설비 기본정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="설비명"
                  name="equipmentName"
                  v-model="equipData.equipmentName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="설비코드"
                  name="equipmentCd"
                  v-model="equipData.equipmentCd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="설비유형"
                  name="equipmentTypeName"
                  v-model="equipData.equipmentTypeName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="메이커"
                  name="maker"
                  v-model="equipData.maker">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="모델"
                  name="model"
                  v-model="equipData.model">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="규격"
                  name="spec"
                  v-model="equipData.spec">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="구입 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="도입처"
                  name="buycustcd"
                  v-model="equipData.buycustcd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="도입처연락처"
                  name="buycusttel"
                  v-model="equipData.buycusttel">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="AS업체"
                  name="ascustcd"
                  v-model="equipData.ascustcd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="AS업체연락처"
                  name="ascusttel"
                  v-model="equipData.ascusttel">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="등급"
                  name="levels"
                  v-model="equipData.levels">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="구입일자"
                  name="buydate"
                  v-model="equipData.buydate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="설치일자"
                  name="setdate"
                  v-model="equipData.setdate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="폐기일자"
                  name="levenddateels"
                  v-model="equipData.enddate">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기타 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="중량"
                  name="weight"
                  v-model="equipData.weight">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="전력용량(KW)"
                  name="epower"
                  v-model="equipData.epower">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="설비상태"
                  name="statusnm"
                  v-model="equipData.statusnm">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="법정검사여부"
                  name="testyn"
                  v-model="equipData.testyn">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="주기단위"
                  name="periodnm"
                  v-model="equipData.periodnm">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="비고"
                  name="remark"
                  v-model="equipData.remark">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="비가동관리여부"
                  name="bgmnyn"
                  v-model="equipData.bgmnyn">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="모니터링No."
                  name="monitorno"
                  v-model="equipData.monitorno">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="기초 가동시간(분)"
                  name="basegdtime"
                  v-model="equipData.basegdtime">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="지표관리여부"
                  name="gpmnyn"
                  v-model="equipData.gpmnyn">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="자산번호"
                  name="assetno"
                  v-model="equipData.assetno">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
export default {
  name: 'equipment-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    equipData: {
      type: Object,
      default: () => ({
        plantCd: '',  // 사업장코드 FACTCD
        equipmentTypeCd: '',  // 설비유형 코드 MECHKIND
        equipmentTypeName: '',  // 설비유형명 MECHKINDNM
        equipmentCd: '',  // 설비코드일련번호 MECHCD
        equipmentNo: '',  // 설비관리번호 MECHSER
        equipmentName: '',  // 설비명 MECHNM
        subProcessCd: '',  // 공정 GONGCD
        wccd: '',  // 라인코드 WCCD
        managementDepts: '',  // 관리부서 MNGDEPCD
        maker: '',  // 메이커
        model: '',  // 모델
        spec: '',  // 규격
        buycustcd: '',  // 도입처
        buycusttel: '',  // 도입처연락처
        ascustcd: '',  // AS업체
        ascusttel: '',  // AS업체연락처
        levels: '',  // 등급
        buydate: '',  // 구입일자
        setdate: '',  // 설치일자
        enddate: '',  // 폐기일자
        setarea: '',  // 설치면적
        weight: '',  // 중량
        epower: '',  // 전력용량(KW)
        status: '',  // 설비상태
        statusnm: '',  // 설비상태명
        testyn: '',  // 법정검사여부
        period: '',  // 주기단위
        periodnm: '',  // 주기단위명
        remark: '',  // 비고
        bgmnyn: '',  // 비가동관리여부
        monitorno: '',  // 모니터링No.
        basegdtime: '',  // 기초 가동시간(분)
        gpmnyn: '',  // 지표관리여부
        assetno: '',  // 자산번호
        buyamt: '',  // 구입금액
        plc: '',  // PLC
        nc: '',  // NC
        pannel: '',  // 터치판넬
        plcyn: '',  // PLC유무
        ncyn: '',  // NC유무
        pannelyn: '',  // 터치판넬유무
        ldate: '',  // 최근수정일시
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = false;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>