var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설비 기본정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비명",
                            name: "equipmentName",
                          },
                          model: {
                            value: _vm.equipData.equipmentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "equipmentName", $$v)
                            },
                            expression: "equipData.equipmentName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비코드",
                            name: "equipmentCd",
                          },
                          model: {
                            value: _vm.equipData.equipmentCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "equipmentCd", $$v)
                            },
                            expression: "equipData.equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비유형",
                            name: "equipmentTypeName",
                          },
                          model: {
                            value: _vm.equipData.equipmentTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "equipmentTypeName", $$v)
                            },
                            expression: "equipData.equipmentTypeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "메이커",
                            name: "maker",
                          },
                          model: {
                            value: _vm.equipData.maker,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "maker", $$v)
                            },
                            expression: "equipData.maker",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "모델",
                            name: "model",
                          },
                          model: {
                            value: _vm.equipData.model,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "model", $$v)
                            },
                            expression: "equipData.model",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "규격",
                            name: "spec",
                          },
                          model: {
                            value: _vm.equipData.spec,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "spec", $$v)
                            },
                            expression: "equipData.spec",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "구입 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "도입처",
                            name: "buycustcd",
                          },
                          model: {
                            value: _vm.equipData.buycustcd,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "buycustcd", $$v)
                            },
                            expression: "equipData.buycustcd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "도입처연락처",
                            name: "buycusttel",
                          },
                          model: {
                            value: _vm.equipData.buycusttel,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "buycusttel", $$v)
                            },
                            expression: "equipData.buycusttel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "AS업체",
                            name: "ascustcd",
                          },
                          model: {
                            value: _vm.equipData.ascustcd,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "ascustcd", $$v)
                            },
                            expression: "equipData.ascustcd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "AS업체연락처",
                            name: "ascusttel",
                          },
                          model: {
                            value: _vm.equipData.ascusttel,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "ascusttel", $$v)
                            },
                            expression: "equipData.ascusttel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "등급",
                            name: "levels",
                          },
                          model: {
                            value: _vm.equipData.levels,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "levels", $$v)
                            },
                            expression: "equipData.levels",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "구입일자",
                            name: "buydate",
                          },
                          model: {
                            value: _vm.equipData.buydate,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "buydate", $$v)
                            },
                            expression: "equipData.buydate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설치일자",
                            name: "setdate",
                          },
                          model: {
                            value: _vm.equipData.setdate,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "setdate", $$v)
                            },
                            expression: "equipData.setdate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "폐기일자",
                            name: "levenddateels",
                          },
                          model: {
                            value: _vm.equipData.enddate,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "enddate", $$v)
                            },
                            expression: "equipData.enddate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기타 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "중량",
                            name: "weight",
                          },
                          model: {
                            value: _vm.equipData.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "weight", $$v)
                            },
                            expression: "equipData.weight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "전력용량(KW)",
                            name: "epower",
                          },
                          model: {
                            value: _vm.equipData.epower,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "epower", $$v)
                            },
                            expression: "equipData.epower",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비상태",
                            name: "statusnm",
                          },
                          model: {
                            value: _vm.equipData.statusnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "statusnm", $$v)
                            },
                            expression: "equipData.statusnm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "법정검사여부",
                            name: "testyn",
                          },
                          model: {
                            value: _vm.equipData.testyn,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "testyn", $$v)
                            },
                            expression: "equipData.testyn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "주기단위",
                            name: "periodnm",
                          },
                          model: {
                            value: _vm.equipData.periodnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "periodnm", $$v)
                            },
                            expression: "equipData.periodnm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비고",
                            name: "remark",
                          },
                          model: {
                            value: _vm.equipData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "remark", $$v)
                            },
                            expression: "equipData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비가동관리여부",
                            name: "bgmnyn",
                          },
                          model: {
                            value: _vm.equipData.bgmnyn,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "bgmnyn", $$v)
                            },
                            expression: "equipData.bgmnyn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "모니터링No.",
                            name: "monitorno",
                          },
                          model: {
                            value: _vm.equipData.monitorno,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "monitorno", $$v)
                            },
                            expression: "equipData.monitorno",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "기초 가동시간(분)",
                            name: "basegdtime",
                          },
                          model: {
                            value: _vm.equipData.basegdtime,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "basegdtime", $$v)
                            },
                            expression: "equipData.basegdtime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "지표관리여부",
                            name: "gpmnyn",
                          },
                          model: {
                            value: _vm.equipData.gpmnyn,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "gpmnyn", $$v)
                            },
                            expression: "equipData.gpmnyn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "자산번호",
                            name: "assetno",
                          },
                          model: {
                            value: _vm.equipData.assetno,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipData, "assetno", $$v)
                            },
                            expression: "equipData.assetno",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }